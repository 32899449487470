const actionTypes = {
	// Audio Player Actions
	SET_SONG: 'SET_SONG',
	SET_PLAYER_STATUS: 'SET_PLAYER_STATUS',

	TOGGLE_PLAYER_LOADING: 'TOGGLE_PLAYER_LOADING',
	TOGGLE_PLAYER_VISIBLE: 'TOGGLE_PLAYER_VISIBLE',
	TOGGLE_PLAYER_IS_MUTED: 'TOGGLE_PLAYER_IS_MUTED',

	// CMS Actions
	SET_ERROR: 'SET_ERROR',
	TOGGLE_VALUE: 'TOGGLE_VALUE',
	SET_DATA: 'SET_DATA',
};

export default actionTypes;
