import { Photo } from 'types';

const mockPhotos: Photo[] = [
	// Khaled Khaled album art - DJ Khaled
	{
		_id: '9865h96785gr976r57h',
		public_id: '89h6597868096nh',
		tags: [],
		width: 1024,
		height: 1024,
		format: 'jpeg',
		bytes: 3498567,
		url: 'http://res.cloudinary.com/mixstudios/image/upload/v1670881950/user-pictures/wahsmgqjy2qah6tcg19w.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670881950/user-pictures/wahsmgqjy2qah6tcg19w.jpg',
		fileName: 'khalidkhalid.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Father Of Asahd album art - DJ Khaled
	{
		_id: 'sdvwe5ytweywce5ywv5',
		public_id: '89h65we5vyvw5er7868096nh',
		tags: [],
		width: 1024,
		height: 1024,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670881961/user-pictures/vytjzhp3rm2daouyxejw.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670881961/user-pictures/vytjzhp3rm2daouyxejw.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Championships album art - Meek Mill
	{
		_id: 'hwv906t98w3tqwv3ft459b ',
		public_id: '89h6597868096nh',
		tags: [],
		width: 300,
		height: 300,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670882344/user-pictures/gbyijunnxobvxye8fu8x.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670882344/user-pictures/gbyijunnxobvxye8fu8x.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Back Of My Mind album art - H.E.R.
	{
		_id: 'vqm947y894hnt9469wvw9347bfg',
		public_id: '89h6597868096nh',
		tags: [],
		width: 300,
		height: 300,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670882424/user-pictures/cijddtjyfuxu7flmkn7s.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670882424/user-pictures/cijddtjyfuxu7flmkn7s.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Khaled Khaled album art - DJ Khaled
	{
		_id: '9865h96785gr976r57h',
		public_id: '89h6597868096nh',
		tags: [],
		width: 1024,
		height: 1024,
		format: 'jpeg',
		bytes: 3498567,
		url: 'http://res.cloudinary.com/mixstudios/image/upload/v1670881950/user-pictures/wahsmgqjy2qah6tcg19w.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670881950/user-pictures/wahsmgqjy2qah6tcg19w.jpg',
		fileName: 'khalidkhalid.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Father Of Asahd album art - DJ Khaled
	{
		_id: 'sdvwe5ytweywce5ywv5',
		public_id: '89h65we5vyvw5er7868096nh',
		tags: [],
		width: 1024,
		height: 1024,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670881961/user-pictures/vytjzhp3rm2daouyxejw.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670881961/user-pictures/vytjzhp3rm2daouyxejw.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Championships album art - Meek Mill
	{
		_id: 'hwv906t98w3tqwv3ft459b ',
		public_id: '89h6597868096nh',
		tags: [],
		width: 300,
		height: 300,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670882344/user-pictures/gbyijunnxobvxye8fu8x.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670882344/user-pictures/gbyijunnxobvxye8fu8x.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Back Of My Mind album art - H.E.R.
	{
		_id: 'vqm947y894hnt9469wvw9347bfg',
		public_id: '89h6597868096nh',
		tags: [],
		width: 300,
		height: 300,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670882424/user-pictures/cijddtjyfuxu7flmkn7s.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670882424/user-pictures/cijddtjyfuxu7flmkn7s.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Khaled Khaled album art - DJ Khaled
	{
		_id: '9865h96785gr976r57h',
		public_id: '89h6597868096nh',
		tags: [],
		width: 1024,
		height: 1024,
		format: 'jpeg',
		bytes: 3498567,
		url: 'http://res.cloudinary.com/mixstudios/image/upload/v1670881950/user-pictures/wahsmgqjy2qah6tcg19w.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670881950/user-pictures/wahsmgqjy2qah6tcg19w.jpg',
		fileName: 'khalidkhalid.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Father Of Asahd album art - DJ Khaled
	{
		_id: 'sdvwe5ytweywce5ywv5',
		public_id: '89h65we5vyvw5er7868096nh',
		tags: [],
		width: 1024,
		height: 1024,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670881961/user-pictures/vytjzhp3rm2daouyxejw.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670881961/user-pictures/vytjzhp3rm2daouyxejw.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Championships album art - Meek Mill
	{
		_id: 'hwv906t98w3tqwv3ft459b ',
		public_id: '89h6597868096nh',
		tags: [],
		width: 300,
		height: 300,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670882344/user-pictures/gbyijunnxobvxye8fu8x.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670882344/user-pictures/gbyijunnxobvxye8fu8x.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Back Of My Mind album art - H.E.R.
	{
		_id: 'vqm947y894hnt9469wvw9347bfg',
		public_id: '89h6597868096nh',
		tags: [],
		width: 300,
		height: 300,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670882424/user-pictures/cijddtjyfuxu7flmkn7s.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670882424/user-pictures/cijddtjyfuxu7flmkn7s.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Khaled Khaled album art - DJ Khaled
	{
		_id: '9865h96785gr976r57h',
		public_id: '89h6597868096nh',
		tags: [],
		width: 1024,
		height: 1024,
		format: 'jpeg',
		bytes: 3498567,
		url: 'http://res.cloudinary.com/mixstudios/image/upload/v1670881950/user-pictures/wahsmgqjy2qah6tcg19w.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670881950/user-pictures/wahsmgqjy2qah6tcg19w.jpg',
		fileName: 'khalidkhalid.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Father Of Asahd album art - DJ Khaled
	{
		_id: 'sdvwe5ytweywce5ywv5',
		public_id: '89h65we5vyvw5er7868096nh',
		tags: [],
		width: 1024,
		height: 1024,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670881961/user-pictures/vytjzhp3rm2daouyxejw.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670881961/user-pictures/vytjzhp3rm2daouyxejw.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Championships album art - Meek Mill
	{
		_id: 'hwv906t98w3tqwv3ft459b ',
		public_id: '89h6597868096nh',
		tags: [],
		width: 300,
		height: 300,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670882344/user-pictures/gbyijunnxobvxye8fu8x.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670882344/user-pictures/gbyijunnxobvxye8fu8x.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
	// Back Of My Mind album art - H.E.R.
	{
		_id: 'vqm947y894hnt9469wvw9347bfg',
		public_id: '89h6597868096nh',
		tags: [],
		width: 300,
		height: 300,
		format: 'jpeg',
		bytes: 3498567,
		url: 'https://res.cloudinary.com/mixstudios/image/upload/v1670882424/user-pictures/cijddtjyfuxu7flmkn7s.jpg',
		secure_url:
			'https://res.cloudinary.com/mixstudios/image/upload/v1670882424/user-pictures/cijddtjyfuxu7flmkn7s.jpg',
		fileName: 'testPhoto.jpeg',
		folder: 'user_photos',
		lastUpdated: '2022-11-26T01:23:17.681Z',
		createdAt: '2022-11-26T01:23:17.681Z',
	},
];

export default mockPhotos;
