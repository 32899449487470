export const awards = [
	{
		artist: 'DJ Khaled',
		type: 'Album',
		item: 'God Did',
		award: 'Grammy',
		year: 2023,
		result: 'Nominated',
		category: 'Best Rap Album',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682607774/srbeats_photos/ft6erxflgibxw2u2ef0v.jpg',
	},
	{
		artist: 'DJ Khaled',
		type: 'Song',
		item: 'God Did (feat. Rick Ross, Lil Wayne, Jay Z, Fridayy & John Legend)',
		award: 'Grammy',
		year: 2023,
		result: 'Nominated',
		category: 'Best Rap Song',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682607774/srbeats_photos/ft6erxflgibxw2u2ef0v.jpg',
	},
	{
		artist: 'DJ Khaled',
		type: 'Song',
		item: 'God Did (feat. Rick Ross, Lil Wayne, Jay Z, Fridayy & John Legend)',
		award: 'Grammy',
		year: 2023,
		result: 'Nominated',
		category: 'Song of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682607774/srbeats_photos/ft6erxflgibxw2u2ef0v.jpg',
	},
	{
		artist: 'DJ Khaled',
		type: 'Song',
		item: 'God Did (feat. Rick Ross, Lil Wayne, Jay Z, Fridayy & John Legend)',
		award: 'Grammy',
		year: 2023,
		result: 'Nominated',
		category: 'Best Rap Performance',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682607774/srbeats_photos/ft6erxflgibxw2u2ef0v.jpg',
	},
	{
		artist: 'Mary J. Blige',
		type: 'Album',
		item: ' Good Morning Gorgeous',
		award: 'Grammy',
		year: 2023,
		result: 'Nominated',
		category: 'Best R&B Album',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682618094/srbeats_photos/cwzqv0z5tiq2yxfkkmqv.jpg',
	},
	{
		artist: 'Mary J. Blige',
		type: 'Album',
		item: ' Good Morning Gorgeous',
		award: 'Grammy',
		year: 2023,
		result: 'Nominated',
		category: 'Album of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682618094/srbeats_photos/cwzqv0z5tiq2yxfkkmqv.jpg',
	},
	{
		artist: 'H.E.R.',
		type: 'Album',
		item: 'Back of My Mind',
		award: 'Grammy',
		year: 2022,
		result: 'Nominated',
		category: 'Album of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682612072/srbeats_photos/ymn1rwrl3yrslr4bsvqv.jpg',
	},
	{
		artist: 'H.E.R.',
		type: 'Album',
		item: 'Back of My Mind',
		award: 'Grammy',
		year: 2022,
		result: 'Nominated',
		category: 'Best R&B Album',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682612072/srbeats_photos/ymn1rwrl3yrslr4bsvqv.jpg',
	},
	{
		artist: 'DJ Khaled',
		type: 'Song',
		item: 'Higher (feat. Nipsey Histle, John Legend)',
		award: 'Grammy',
		year: 2019,
		result: 'Won',
		category: 'Best Rap/Sung Performance',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682607774/srbeats_photos/ft6erxflgibxw2u2ef0v.jpg',
	},
	{
		artist: 'Meek Mill',
		type: 'Album',
		item: 'Championships',
		award: 'Grammy',
		year: 2019,
		result: 'Nominated',
		category: 'Rap Album of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682618455/srbeats_photos/bi6er1llnzmus0lhwrs1.jpg',
	},
	{
		artist: 'Eminem',
		type: 'Album',
		item: 'Marshal Mathers LP 2',
		award: 'Grammy',
		year: 2015,
		result: 'Won',
		category: 'Rap Album of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682608701/srbeats_photos/m0khrujkzfuuyvtqyooi.jpg',
	},
	{
		artist: '2 Chainz',
		type: 'Album',
		item: 'Based on a T.R.U. Story',
		award: 'Grammy',
		year: 2013,
		result: 'Nominated',
		category: 'Rap Album of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682487594/srbeats_photos/qozdz5zn7xnrl7jso4vn.jpg',
	},
	{
		artist: 'Lil Wayne',
		type: 'Album',
		item: 'The Carter IV',
		award: 'Grammy',
		year: 2012,
		result: 'Nominated',
		category: 'Rap Album of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682616523/srbeats_photos/yyznaxzyjlny5iso3spl.jpg',
	},
	{
		artist: 'Lil Wayne',
		type: 'Album',
		item: 'The Carter III',
		award: 'Grammy',
		year: 2008,
		result: 'Won',
		category: 'Rap Album of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682616218/srbeats_photos/jmkanhz3gjk09udhaxvu.jpg',
	},
	{
		artist: 'Lil Wayne',
		type: 'Album',
		item: 'The Carter III',
		award: 'Grammy',
		year: 2012,
		result: 'Nominated',
		category: 'Album of the Year',
		img: 'https://res.cloudinary.com/dcbkcdxat/image/upload/v1682616218/srbeats_photos/jmkanhz3gjk09udhaxvu.jpg',
	},
];
